<template lang="html">
    <div>
        NOT FOUND!
    </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
</style>
