<template>
    <DsCard>
        <header class="card-header">
            <h5>
                Latest comments
            </h5>
        </header>

        <CommentsList :comments="sortedComments" />

        <br />
    </DsCard>
</template>

<script>
import CommentsList from '@/components/CommentsList';
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';

export default {
    components: {
        CommentsList,
    },

    computed: {
        ...mapState({
            comments: ({ hackathons }) => hackathons.comments,
        }),

        ...mapGetters({
            getProject: 'projects/getProject',
            getUser: 'users/getUser',
        }),

        sortedComments() {
            return this.comments
                ?.map(({ projectId, comments }) => comments.map((comment) => ({
                    ...comment,
                    project: this.getProject(projectId),
                    author: this.getUser(comment.authorId),
                    displayDate: moment(comment.dateAdded).fromNow(),
                })))
                ?.flat()
                ?.filter(({ author }) => author?.id)
                ?.sort((a, b) => {
                    if (a.dateAdded > b.dateAdded) return -1;
                    if (a.dateAdded < b.dateAdded) return 1;

                    return 0;
                })?.slice(0, 5);
        },
    },
};
</script>
