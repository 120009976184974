<template>
    <div class="home-page">
        <ActiveHackathonBanner v-if="activeHackathon" />
        <LatestRallyIdeas v-if="isRally" />

        <template v-else>
            <LatestProjects />
            <LatestComments />
            <LatestIdeas />
            <RecentEvents />
        </template>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import RecentEvents from '@/components/RecentEvents';
import ActiveHackathonBanner from '@/components/ActiveHackathonBanner';
import LatestComments from '@/components/LatestComments';
import LatestProjects from '@/components/LatestProjects';
import LatestRallyIdeas from '@/components/LatestRallyIdeas';
import LatestIdeas from '@/components/LatestIdeas';
import '@/assets/images/page-not-found.svg';

export default {
    components: {
        RecentEvents,
        ActiveHackathonBanner,
        LatestComments,
        LatestProjects,
        LatestRallyIdeas,
        LatestIdeas,
    },

    data() {
        return {
            loading: true,
            collapseHeader: false,
            uploader: {
                progress: 0,
                file: null,
                interval: 0,
            },
        };
    },

    mounted() {
        if (!this.user?.uid) this.$router.replace({ name: 'login' });
    },

    computed: {
        ...mapState({
            isAdmin: ({ auth }) => auth.user.isAdmin,
            comments: ({ hackathons }) => hackathons.comments,
            user: ({ auth }) => auth.user,
            projects: ({ projects }) => projects.projects,
        }),

        ...mapGetters({
            activeHackathon: 'hackathons/getActiveHackathon',
            getProjectsByHackathon: 'projects/getProjectsByHackathon',
        }),

        isRally() {
            return this.activeHackathon?.type === 'rally';
        },

        rallyProjects() {
            return this.getProjectsByHackathon(this.activeHackathon?.id);
        },
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
    .home-page {
        display: flex;
        flex-direction: column;
        gap: $spacing-200;
    }
</style>
