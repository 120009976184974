<template>
    <div class="login-page">
        <div v-if="authenticating" class="loading-spinner centered white">
            <DsSpinner />
        </div>

        <div v-else class="login-box">
            <DsLogo />

            <h4>PD Events</h4>

            <!-- Share, Collaborate, Learn, Hack, Develop, Browse Etc... -->

            <DsFilledButton
                v-if="user.uid"
                class="login-button"
                @click="logout"
            >
                Log out
            </DsFilledButton>

            <DsFilledButton
                v-else
                :loading="loading"
                class="login-button"
                @click="login"
            >
                Login
            </DsFilledButton>

            <br />

            <small>Use your @keap Google account</small>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export default {
    data() {
        return {
            loading: false,
            authenticating: false,
        };
    },

    mounted () {
        if (this.user.uid) this.$router.push({ name: 'home' });
    },

    watch: {
        // eslint-disable-next-line
        ['user.uid'](value) {
            if (value) this.checkAccess();
        },
    },

    computed: {
        ...mapState({
            googleToken: ({ auth }) => auth.googleToken,
            user: ({ auth }) => auth.user,
        }),

        ...mapGetters({
            guestsList: 'users/guestsList',
        }),
    },

    methods: {

        async checkAccess() {
            this.loading = true;

            const userEmail = this.user?.email;

            if (userEmail?.endsWith('@keap.com')) {
                this.$router.push({ name: 'home' });

                return;
            }

            try {
                await this.$store.dispatch('users/LOAD_GUEST_LIST');

                if (this.guestsList.includes(userEmail)) {
                    this.$router.push({ name: 'home' });
                } else {
                    this.$error({ message: 'Access restricted.', bottom: true });
                    this.loading = false;
                    this.logout();
                }
            } catch (e) {
                //
            }
        },

        logout() {
            firebase.auth().signOut().then(() => {
                this.$store.commit('auth/SET_USER', { email: '' });
                this.$store.commit('auth/SET_GOOGLE_TOKEN', '');
                this.$router.push({ name: 'login' });
            }).catch(() => {});
        },

        login() {
            this.loading = true;

            if (!this.googleToken) {
                this.firebaseSignin();

                return;
            }

            const { currentUser } = firebase.auth();

            if (!currentUser) {
                this.$store.commit('auth/SET_GOOGLE_TOKEN', '');
                this.firebaseSignin();
            }
        },

        async firebaseSignin() {
            const provider = new firebase.auth.GoogleAuthProvider();

            try {
                this.authenticating = true;

                await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

                const result = await firebase.auth().signInWithPopup(provider);

                this.authenticating = Boolean(result.user);

                if (result.credential && !this.googleToken) {
                    this.$store.commit('auth/SET_GOOGLE_TOKEN', result.credential.idToken);
                    this.$store.commit('auth/SET_UNAUTHENTICATED_COUNT', 0);

                    const db = firebase.firestore();

                    const { creationTime, lastSignInTime } = result.user.metadata;
                    const { email, name, picture } = result.additionalUserInfo.profile;

                    const user = {
                        uid: result.user.uid,
                        email,
                        name,
                        photoUrl: picture,
                        creationTime,
                        lastSignInTime,
                    };

                    db.collection('users').doc(result.user.uid).get()
                        .then((doc) => {
                            if (doc.data()) {
                                this.$store.commit('auth/SET_USER', {
                                    isAdmin: doc.data().isAdmin,
                                    ...user,
                                });
                            } else {
                                db.collection('users').doc(result.user.uid).set(user)
                                    .then((newDoc) => {
                                        this.$store.commit('auth/SET_USER', {
                                            isAdmin: newDoc.data().isAdmin,
                                            ...user,
                                        });
                                    })
                                    .catch((e) => {
                                        // eslint-disable-next-line no-console
                                        console.log('error', e);
                                    });
                            }
                        })
                        .catch((e) => {
                            // eslint-disable-next-line no-console
                            console.log('error', e);
                        });


                    if (result?.credential?.idToken) this.$store.commit('auth/SET_GOOGLE_TOKEN', result.credential.idToken);

                    this.$store.commit('auth/SET_USER', {
                        uid: user.uid,
                        email: user.email,
                        name: user.displayName,
                        photoUrl: user.photoURL,
                    });
                }
            } catch (error) {
                // TODO: handle error
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .login-page {
        display: flex;
        justify-content: center;
    }

    .login-box {
        display: flex;
        flex-direction: column;
        width: 480px;
        max-width: 100%;
        align-items: center;
        margin-top: 100px;
        z-index: 1;
        position: absolute;
        padding: $spacing-400;
        border: 1px solid $color-gray-300;
        border-radius: $border-radius;
        background-color: $color-white;

        .login-button {
            margin-top: $spacing-400;
            --button-background-color: black;
            --button-text-color: white;
        }

        small {
            color: $color-gray-500;
        }
    }
</style>
