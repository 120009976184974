<template>
    <div class="active-hackathon">
        <router-link :to="{ name: 'hackathon', params: { id: activeHackathon.id } }">
            <img :src="activeHackathon.image" class="hackathon-image" />
        </router-link>

        <aside>
            <h3>{{ activeHackathon.name }}</h3>

            <p v-if="timeUntilHackathon > 0" class="subtitle">
                Event starts in <strong>{{ timeUntilHackathon }} {{ timeUnit }}</strong>
            </p>

            <br />

            <DsFilledButton
                v-if="isRally && isAdmin"
                gray
                class="new-project-button"
                @click="$router.push({ name: 'projects.new' })"
            >
                Create project
                <DsStatus emphasis="high">
                    Admin
                </DsStatus>
            </DsFilledButton>

            <DsFilledButton
                v-if="!isRally"
                gray
                class="new-project-button"
                @click="$router.push({ name: 'projects.new' })"
            >
                Create project
            </DsFilledButton>

            <DsFilledButton
                @click="$router.push({ name: 'ideas.new' })"
            >
                Submit an idea
            </DsFilledButton>
        </aside>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';

export default {
    computed: {
        ...mapGetters({
            activeHackathon: 'hackathons/getActiveHackathon',
        }),

        ...mapState({
            isAdmin: ({ auth }) => auth.user.isAdmin,
        }),

        daysUntilHackathon() {
            return moment(this.activeHackathon?.startDate).diff(moment(), 'days');
        },

        timeUntilHackathon() {
            return moment(this.activeHackathon?.startDate).diff(moment(), this.timeUnit);
        },

        timeUnit() {
            return this.daysUntilHackathon < 1
                ? 'hours'
                : 'days';
        },

        isRally() {
            return this.activeHackathon.type === 'rally';
        },
    },
};
</script>

<style lang="scss" scoped>
.active-hackathon {
    display: grid;
    background-color: $color-white;
    border-radius: $border-radius;
    grid-gap: $spacing-200;
    padding: $spacing-200;
    grid-template-columns: 300px 1fr;

    @media($extra-small) {
        grid-template-columns: auto;
    }
}

.hackathon-image {
    border-radius: $border-radius;
}

.subtitle {
    color: $color-green;
    font-size: $font-size-lg;
}

.new-project-button {
    margin-right: $spacing-200;
}
</style>
